exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-my-menu-mymenu-content-js": () => import("./../../../src/templates/my-menu/mymenu_content.js" /* webpackChunkName: "component---src-templates-my-menu-mymenu-content-js" */),
  "component---src-templates-my-menu-mymenu-main-js": () => import("./../../../src/templates/my-menu/mymenu_main.js" /* webpackChunkName: "component---src-templates-my-menu-mymenu-main-js" */),
  "component---src-templates-my-menu-mymenu-page-js": () => import("./../../../src/templates/my-menu/mymenu_page.js" /* webpackChunkName: "component---src-templates-my-menu-mymenu-page-js" */),
  "component---src-templates-my-menu-mymenu-recipe-js": () => import("./../../../src/templates/my-menu/mymenu_recipe.js" /* webpackChunkName: "component---src-templates-my-menu-mymenu-recipe-js" */),
  "component---src-templates-my-menu-no-content-js": () => import("./../../../src/templates/my-menu/no_content.js" /* webpackChunkName: "component---src-templates-my-menu-no-content-js" */)
}

